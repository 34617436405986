@use '@queries' as *;
@use '@typography' as *;
@use '@button' as *;
@use '@fixed-size' as *;

.container {
  width: 100%;
  height: max-content;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.header {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  height: max-content;

  &__title {
    color: #212121;
    @include typography-base;
    @include weight-semi-bold;
    @include min-1440-break {
      @include typography-m;
    }
  }
  &__description {
    color: #646464;
    @include typography-s;
    @include weight-medium;
    @include min-1440-break {
      @include typography-base;
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  width: 484px;
  gap: 10px;
  @include min-1440-break {
    width: 548px;
  }
}

.skeleton {
  border-radius: var(--cornerradius8);
  @include fixedSize(484px, 52px);

  @include min-1440-break {
    @include fixedSize(548px, 52px);
  }
}

.status-icon {
  position: relative;
  cursor: default;
  pointer-events: auto;
}

.tooltip {
  position: absolute;
  z-index: 5;
  top: -65px;
  height: 40px;
  left: -100%;
  width: max-content;
  &-long {
    left: -180%;
  }
  &_body {
    border-radius: var(--cornerradius8);
    border: 1px solid #f6f6f6;
    box-shadow: var(--elevation-1);
    background-color: #ffffff;
    padding: 10px 16px;
  }
  &_text {
    color: #303030;
  }
}

.input {
  pointer-events: none;
}

.btn {
  @include buttonSizes('38', '38', '38', '38', '42', '42');
  width: 205px !important;
  @include min-1440-break {
    width: 208px !important;
  }
}
