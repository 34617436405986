.empty {
  align-self: center;
  padding: 6px 0;
}

.virtualized-list {
  div[id='virtualized-grid-components--list'] {
    div[id='virtualized-grid-components--item'] {
      width: 20%;
    }

    div[id='skeleton'] {
      width: 15%;

      span {
        width: 100%;
        padding: 18px 10px;
        margin-bottom: 0px;
      }
    }
  }
}
