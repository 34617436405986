@use '@queries' as *;
@use '@fixed-size' as *;
@use '@typography' as *;
@use '@content' as *;

.content {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 14px;

  @include min-1440-break {
    gap: 24px;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .input_container {
      display: flex;
      width: 100%;
      max-width: 232px;

      @include min-1440-break {
        max-width: 328px;
      }
    }

    .tabs-container {
      gap: 0;
      border-radius: var(--cornerradius10);
      background: var(--cst-live-feed-background-tabs, #f6f6f6);
      width: 266px;
      > button {
        padding: 11px 18px;
        min-height: 42px;
        p {
          @include typography-s;
          @include weight-medium;
        }
      }

      @include min-1440-break {
        border-radius: var(--cornerradius10);
        width: 318px;
        min-height: 42px;
        > button {
          padding: 11px 18px;
          p {
            @include typography-m;
          }
        }
      }
    }
  }

  &__table {
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex: 1;
    width: 100%;
    max-height: 100%;

    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 12px;
      background: var(--cst-history-content-background);
      @include fixedSize(100%, 38px);
      border-radius: var(--cornerradius10);
      @include min-1440-break {
        @include fixedSize(100%, 42px);
      }

      .title {
        display: flex;
        flex: 1;
        justify-content: center;
        @include typography-s;
        @include weight-medium;
        color: var(--cst-history-content-text);

        @include min-1440-break {
          @include typography-base;
        }
      }
    }

    &-body-wrapper {
      display: flex;
      flex: 1;
      flex-direction: column;
      gap: 14px;
      max-height: 380px;
      overflow-y: auto;
      mask: linear-gradient(180deg, #0000, #000 0% 92%, #0000);
      //@include hideScrollbar;
      @include min-1440-break {
        max-height: 480px;
      }

      @include min-1920-break {
        max-height: 650px;
      }
    }

    &-body {
      display: flex;
      flex-direction: column;
      gap: 8px;
      width: 100%;
      flex: 1;
      max-height: inherit;

      @include min-1440-break {
        gap: 14px;
      }

      * > div[data-testid='virtuoso-item-list'] {
        display: flex;
        flex-direction: column;
        gap: 8px;

        @include min-1440-break {
          gap: 14px;
        }
        div[data-index] {
          display: flex;
          flex-direction: column;
          gap: 8px;

          @include min-1440-break {
            gap: 14px;
          }
        }
      }

      &-empty {
        display: flex;
        min-width: 100%;
        flex: 1;
        justify-content: center;
        align-items: center;
        min-height: 60dvh;

        @include min-1024-break {
          min-height: initial;
        }
      }
    }

    &-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 12px;
      @include fixedSize(100%, 66px);
    }
  }
}

.content-components {
  display: flex;
  flex: 1;
  justify-content: center;

  &__type,
  &__price,
  &__date,
  &__id,
  &__nickname,
  &__status,
  &__method,
  &__total {
    text-align: center;
    @include typography-s;
    @include weight-medium;
    color: var(--cst-cards-history-text);

    @include min-1440-break {
      @include typography-base;
    }
  }

  &__status {
    &-danger {
      color: var(--cst-tag-status-text-closed);
    }

    &-warning {
      color: var(--cst-tag-status-text-process);
    }

    &-success {
      color: var(--cst-tag-status-text-sucess);
    }
  }

  &__properties {
    display: flex;
    align-items: center;
    width: max-content;
    padding: 6px;
    gap: 6px;
    border-radius: var(--cornerradius6);
    background: var(--cst-cards-shoping-item-background-tags);

    @include min-1440-break {
      gap: 11px;
    }
  }
}

.icon {
  @include singleFixedSize(18px);
}

.item-info-wrapper {
  display: flex;
  align-items: center;
  gap: 4px;
  width: 100%;

  @include min-1440-break {
    gap: 24px;
  }

  .item-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .item-rarity {
      position: absolute;
      z-index: 0;

      @include singleFixedSize(42px);

      @include min-1440-break {
        @include singleFixedSize(52px);
      }
    }

    .item {
      pointer-events: none;
      z-index: 1;
      @include singleFixedSize(42px);
    }
  }

  .item-titles {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 2px;
    max-width: 75px;

    @include min-1440-break {
      max-width: 100px;
    }
    .title {
      max-width: inherit;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: block;
      overflow: hidden;
      @include typography-s;
      @include weight-medium;
      color: var(--cst-cards-history-text);

      @include min-1440-break {
        @include typography-base;
      }
    }

    .subtitle {
      text-transform: capitalize;
      @include typography-xs;
      @include weight-medium;
      color: var(--cst-cards-history-subtitle);

      @include min-1440-break {
        @include typography-s;
      }
    }
  }
}

.empty-info {
  max-width: 282px;
  @include min-1024-break {
    max-width: 224px;
  }
}
