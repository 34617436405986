@use '@queries' as *;

.breadcrumbs {
  @include min-1024-break {
    min-height: 18px;
    max-height: 18px;
  }
  @include min-1440-break {
    min-height: 24px;
    max-height: 24px;
  }
}
